// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_sheet1 extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "data";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Generic JSON'.
    
    item = {};
    this.items.push(item);
    item['status'] = "published";
    item['id'] = "3";
    item['Alias'] = "@gmpuniverse";
    item['user_created'] = "0e42d2c8-4d5a-4d45-8b0b-00862324c681";
    item['date_created'] = "2022-07-24T03:48:34.535Z";
    item['Content'] = "https://showcase.nassawautobrokers.com/web-stories/draft-media-kit-2022/";
    item['Title'] = "DVA Benefit Concert Raw Footage";
    item['Image'] = "https://ipfs.io/ipfs/bafybeid4cdgvqdcmdibn7ytxfdus44rhjcji2rqbdnrrngqk3abxf56hby/GMPwebsite003.png";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['status'] = "published";
    item['id'] = "4";
    item['Alias'] = "@aroundthenfl";
    item['user_created'] = "0e42d2c8-4d5a-4d45-8b0b-00862324c681";
    item['date_created'] = "2022-07-24T04:11:35.110Z";
    item['Content'] = "https://twitter.com/AroundTheNFL/status/1550567606829977600";
    item['Title'] = "\"Titans agree to terms with second-round CB Roger McCreary on rookie deal; only QB Malik Willis remains unsigned  https://t.co/ex...\"";
    item['Image'] = "https://pbs.twimg.com/media/FYS3xhCUEAAcEAt?format=jpg&name=large";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['status'] = "published";
    item['id'] = "5";
    item['Alias'] = "@aroundthenfl";
    item['user_created'] = "0e42d2c8-4d5a-4d45-8b0b-00862324c681";
    item['date_created'] = "2022-07-24T04:13:01.256Z";
    item['Content'] = "https://twitter.com/AroundTheNFL/status/1550573415450390528";
    item['Title'] = "\"Andy Reid on Orlando Brown reporting for Chiefs camp: \"\"I don't know whether he's going to be here or not\"\"  https://t.co/qk4diJJF...\"";
    item['Image'] = "https://pbs.twimg.com/media/FYS3xhCUEAAcEAt?format=jpg&name=large";
    item.key = key++;
  }

  
  urlFromOptions(options) {
    const baseUrl = "https://ffo4vnt2.directus.app/items/unlimitednow";
    
    let path = options.servicePath || '';
    if (path.length > 0 && path.substr(0, 1) !== '/' && baseUrl.substr(baseUrl.length - 1, 1) !== '/')
      path = '/'+path;
  
    let query = options.query || '';
    if (query.length > 0) {
      const dataSlots = options.dataSlots || {};
      query = "?" + this.expandSlotTemplateString(query, dataSlots);
    }
  
    return baseUrl + path + query; 
  }
  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  addItem(item, options) {
    super.addItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
  
    const fetchOpts = {
      method: 'POST',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  removeItem(item, options) {
    super.removeItem(item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
  
    const fetchOpts = {
      method: 'DELETE',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  
  replaceItemByRowIndex(idx, item, options) {
    super.replaceItemByRowIndex(idx, item, options);
    
    const url = this.urlFromOptions(options);
    
    let headers = {};
    headers['Content-Type'] = 'application/json';
  
    const fetchOpts = {
      method: 'PUT',
    	headers: headers,
    	body: JSON.stringify(item),
    };
  
    fetch(url, fetchOpts)
      .then((response) => {
        if (response.status >= 400) {
          console.log("** Error sending %s to %s, response: ", fetchOpts.method, url, response);
          throw new Error("Server error: "+response.status);
        }
        return response.json();
      })
      .then((json) => {
        this._fetchComplete(null, options);
      })
      .catch((exc) => {
        this._fetchComplete(exc, options);
      });  
  }
  

}
